/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { graphql, StaticQuery } from 'gatsby';

import { Status } from '@af/design-system-docs-ui';
import { useSetColorMode, useSetTheme, useTheme } from '@atlaskit/app-provider';
import Button from '@atlaskit/button/new';
import DropdownMenu, { DropdownItemRadio, DropdownItemRadioGroup } from '@atlaskit/dropdown-menu';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';

import { LOCAL_STORAGE_THEME_KEY } from '../../utils/constants';
import isProd from '../../utils/is-prod';

type AppTheme = 'light' | 'dark' | 'auto' | 'none';

const themeData = {
	light: {
		label: 'Light',
		description: null,
		imageSrc: 'modeLightThumb',
		status: null,
	},
	dark: {
		label: 'Dark',
		description: null,
		imageSrc: 'modeDarkThumb',
		status: null,
	},
	auto: {
		label: 'Match browser',
		description: null,
		imageSrc: 'modeLightThumb',
		status: null,
	},
	none: {
		label: 'Original light',
		description: 'No design tokens',
		imageSrc: 'modeNoneThumb',
		status: null,
	},
} as const;

const typographyThemeData = {
	'typography-adg3': {
		label: 'Legacy',
		description: 'Legacy typography',
		imageSrc: 'modeLightThumb',
		status: null,
	},
	'typography-modernized': {
		label: 'Modernised',
		description: 'Modernised typography theme',
		imageSrc: 'modeLightThumb',
		status: 'alpha',
	},
} as const;

type TypographyTheme = keyof typeof typographyThemeData;

const ThemeImg = ({ src }: { src: string }) => (
	<Box
		alt="alt"
		as="img"
		src={src}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		xcss={xcss({
			width: '4rem',
			height: '3rem',
			borderRadius: 'border.radius',
		})}
	/>
);

export default function Settings() {
	const [isOpen, setIsOpen] = useState(false);
	const [appTheme, setAppTheme] = useState<AppTheme>('none');
	const [systemTheme, setSystemTheme] = useState<'light' | 'dark'>('light');
	const { typography: typographyTheme } = useTheme();
	const setTheme = useSetTheme();
	const setColorMode = useSetColorMode();

	useEffect(() => {
		const localStorageTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
		if (
			localStorageTheme === 'light' ||
			localStorageTheme === 'dark' ||
			localStorageTheme === 'auto'
		) {
			// If a light/dark/auto theme has previously been set, use that theme
			setAppTheme(localStorageTheme);
			setColorMode(localStorageTheme);
		} else if (localStorageTheme === 'none') {
			// If 'none' has previously been set, use that (do not load tokens)
			setAppTheme('none');
		} else {
			// If nothing set previously, set default theme to 'auto' and load tokens
			setAppTheme('auto');
			setColorMode('auto');
		}
	}, [setColorMode]);

	useEffect(() => {
		const mql = window.matchMedia('(prefers-color-scheme: dark)');
		const listener = (e: MediaQueryListEvent) => setSystemTheme(e.matches ? 'dark' : 'light');

		mql.addEventListener('change', listener);
		return () => mql.removeEventListener('change', listener);
	}, []);

	const handleAppThemeChange = (theme: AppTheme) => {
		setAppTheme(theme);
		localStorage.setItem(LOCAL_STORAGE_THEME_KEY, theme);
		if (theme === 'none') {
			const element = document.documentElement;
			element.removeAttribute('data-theme');
			element.removeAttribute('data-color-mode');
		} else {
			setColorMode(theme || appTheme);
			if (appTheme === 'none') {
				// If the the app theme is currently set to 'none',
				// the color mode context may not change here, which means the theme
				// data attributes will not get reapplied, so we must reapply them.
				setTheme({});
			}
		}
	};

	return (
		<StaticQuery
			query={graphql`
				query modeLightThumb {
					modeLightThumb: file(relativePath: { eq: "theme-thumbs/LightThumb.svg" }) {
						publicURL
					}
					modeDarkThumb: file(relativePath: { eq: "theme-thumbs/DarkThumb.svg" }) {
						publicURL
					}
					modeNoneThumb: file(relativePath: { eq: "theme-thumbs/NoneThumb.svg" }) {
						publicURL
					}
				}
			`}
			render={(data) => (
				<DropdownMenu<HTMLButtonElement>
					placement="bottom-end"
					isOpen={isOpen}
					onOpenChange={() => setIsOpen(!isOpen)}
					trigger={({ triggerRef, onClick, ...triggerProps }) => (
						<Button
							ref={triggerRef}
							onClick={(e) => {
								// @ts-expect-error
								onClick(e);
								setIsOpen(!isOpen);
							}}
							{...triggerProps}
						>
							Theme
						</Button>
					)}
				>
					<DropdownItemRadioGroup id="color-theme" title="Color theme">
						{Object.entries(themeData).map(([theme, metadata]) => (
							<DropdownItemRadio
								id={theme}
								key={theme}
								isSelected={appTheme === theme}
								onClick={() => handleAppThemeChange(theme as AppTheme)}
							>
								<Inline space="space.150" alignBlock="center">
									<ThemeImg
										src={
											data[
												themeData[
													theme === 'auto' ? systemTheme : (theme as keyof typeof themeData)
												].imageSrc
											].publicURL
										}
									/>
									<Stack>
										<Text>
											{metadata.label} {metadata.status && <Status type={metadata.status} />}
										</Text>
										<Text size="UNSAFE_small" color="color.text.subtlest">
											{metadata.description}
										</Text>
									</Stack>
								</Inline>
							</DropdownItemRadio>
						))}
					</DropdownItemRadioGroup>
					{!isProd() && (
						<DropdownItemRadioGroup id="typography-theme" title="Typography">
							{Object.entries(typographyThemeData).map(([theme, metadata]) => (
								<DropdownItemRadio
									id={theme}
									key={theme}
									isSelected={typographyTheme === theme}
									onClick={() => setTheme({ typography: theme as TypographyTheme })}
								>
									<Inline space="space.150" alignBlock="center">
										<ThemeImg src={data['modeLightThumb'].publicURL} />
										<Stack>
											<Text>
												{metadata.label} {metadata.status && <Status type={metadata.status} />}
											</Text>
											<Text size="UNSAFE_small" color="color.text.subtlest">
												{metadata.description}
											</Text>
										</Stack>
									</Inline>
								</DropdownItemRadio>
							))}
						</DropdownItemRadioGroup>
					)}
				</DropdownMenu>
			)}
		/>
	);
}
