import React from 'react';

import { SpotlightCard } from '../../src';

const SpotlightCardRichTextExample = () => {
	return (
		<SpotlightCard>
			<div>
				All your <strong>Projects</strong> and <strong>Issues</strong>, including the ones you've
				just created can be found in the sidebar.
			</div>
		</SpotlightCard>
	);
};

export default SpotlightCardRichTextExample;
