/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type FC, type HTMLAttributes, useCallback, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import useClipboard from 'react-use-clipboard';

import Button from '@atlaskit/button';
import { Code } from '@atlaskit/code';
import CheckIcon from '@atlaskit/icon/glyph/check';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import type { IconData } from '../types';

type CopiedCodePopupProps = HTMLAttributes<HTMLDivElement> & {
	importStatement: string;
};

type IconCellProps = IconData & {
	testId?: string;
};

const buttonStyles = css({
	display: 'flex',
	width: '120px',
	height: '96px',
	padding: `${token('space.250', '20px')} ${token('space.100', '8px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.100', '8px')}`,
	alignItems: 'center',
	justifyContent: 'space-between',
	flexDirection: 'column',
	color: token('color.text'),
	// eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		flexGrow: 'unset',
	},
	// eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span > span': {
		color: token('color.text.subtle'),
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.pressed'),
	},
});

const buttonHoverStyles = css({
	backgroundColor: token('color.background.neutral.hovered'),
});

const iconNameStyles = css({
	width: '120px',
	font: token('font.body'),
	whiteSpace: 'normal',
	wordWrap: 'break-word',
});

const popupTextContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	color: token('color.text.success'),
});

const popupStyles = css({
	padding: token('space.100', '8px'),
});

const CopiedCodePopup: FC<CopiedCodePopupProps> = ({
	importStatement,
	onMouseEnter,
	onMouseLeave,
}) => (
	<div css={popupStyles} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
		<div css={popupTextContainerStyles}>
			<CheckIcon label="Check Icon" />
			Import copied to clipboard!
		</div>
		<Code>{importStatement}</Code>
	</div>
);

/**
 * __Icon cell__
 *
 * A single cell which is used in the grid of icons.
 * Contains the icon, click/hover behaviour, popup, and tooltip.
 */
const IconCell: FC<IconCellProps> = ({
	component,
	iconName,
	packageName,
	componentName,
	testId,
}) => {
	// copy to clipboard logic
	const importStatement = `import ${componentName} from '${packageName}'`;
	const setCopied = useClipboard(importStatement || '')[1];

	const [isPopupOpen, setPopupOpen] = useState(false);
	const [isMouseOver, setIsMouseOver] = useState(false);
	const isMouseOverRef = useRef(isMouseOver);
	isMouseOverRef.current = isMouseOver;
	let timeout: NodeJS.Timeout;

	const delayedSetClose = (isCursorOver: boolean) => {
		timeout = setTimeout(() => {
			if (!isMouseOverRef.current) {
				setPopupOpen(false);
			}
		}, 800);
	};

	const onButtonClick = useCallback(
		(evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
			// copy to clipboard
			evt.stopPropagation();
			setCopied();

			// open popup
			setPopupOpen(true);
		},
		[setCopied],
	);

	const mouseEnterHandler = () => {
		setIsMouseOver(true);
		clearTimeout(timeout);
	};

	const mouseLeaveHandler = () => {
		setIsMouseOver(false);
		delayedSetClose(isMouseOverRef.current);
	};

	const Icon = component;
	return (
		<span data-testid={testId}>
			<Popup
				testId="copy-code-popup"
				isOpen={isPopupOpen}
				onClose={() => setPopupOpen(false)}
				content={() => (
					<CopiedCodePopup
						importStatement={importStatement}
						onMouseEnter={mouseEnterHandler}
						onMouseLeave={mouseLeaveHandler}
					/>
				)}
				placement="bottom-start"
				trigger={(triggerProps) => (
					<Tooltip content="Copy import" position="top" hideTooltipOnMouseDown={true}>
						<Button
							{...triggerProps}
							css={[buttonStyles, isMouseOver && buttonHoverStyles]}
							appearance="subtle"
							onClick={(evt) => {
								onButtonClick(evt);
								delayedSetClose(isMouseOverRef.current);
							}}
							onMouseEnter={mouseEnterHandler}
							onMouseLeave={mouseLeaveHandler}
						>
							<Icon />
							<div css={iconNameStyles}>{iconName}</div>
						</Button>
					</Tooltip>
				)}
			/>
		</span>
	);
};

export default IconCell;
