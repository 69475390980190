/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import BaseTable from './components/base-table';
import { COLOR_ROLES } from './table-data';

/**
 * __Color roles table__
 *
 * A color roles table describes the intention behind the color.
 *
 */
const ColorRolesTable = () => <BaseTable tableData={COLOR_ROLES} categoryName="Role" />;

export default ColorRolesTable;
