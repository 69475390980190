/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx, css } from '@emotion/react';
import { N300 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { Anchor, xcss } from '@atlaskit/primitives';

const footerNavStyles = css({
	display: 'flex',
	gap: token('space.400', '32px'),
	justifyContent: 'center',
	textAlign: 'center',
	color: token('color.text.subtlest', N300),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	paddingInlineStart: token('space.1000', '80px'),
	paddingInlineEnd: token('space.1000', '80px'),
	paddingBlockStart: token('space.300', '24px'),
	paddingBlockEnd: token('space.300', '24px'),
	[media.below.sm]: {
		paddingInlineStart: token('space.100', '8px'),
		paddingInlineEnd: token('space.100', '8px'),
		flexDirection: 'column',
		gap: token('space.100', '8px'),
		rowGap: token('space.100', '8px'),
	},
});

const navLinksStyles = css({
	display: 'flex',
	marginBlockStart: token('space.0', '0'),
	padding: token('space.0', '0'),
	justifyContent: 'center',
	gap: token('space.400', '32px'),
	listStyle: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	li: {
		display: 'inline-flex',
		marginBlockStart: token('space.0', '0'),
	},
	[media.below.sm]: {
		flexWrap: 'wrap',
		gap: token('space.200', '16px'),
	},
});

const navLinkStyles = xcss({
	textDecoration: 'none',
	color: 'color.text.subtlest',
	':hover': {
		color: 'color.text.subtle',
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.text',
	},
});

const FooterContent = () => (
	<nav css={footerNavStyles}>
		<p>&copy; {new Date().getFullYear()} Atlassian</p>
		<ul css={navLinksStyles}>
			<li>
				<Anchor
					href="https://www.atlassian.com/company/careers"
					target="_blank"
					rel="noopener"
					xcss={navLinkStyles}
				>
					Careers
				</Anchor>
			</li>
			<li>
				<Anchor
					href="https://www.atlassian.com/legal/trademark"
					target="_blank"
					rel="noopener"
					xcss={navLinkStyles}
				>
					Trademark
				</Anchor>
			</li>
			<li>
				<Anchor
					href="https://www.atlassian.com/legal/privacy-policy"
					target="_blank"
					rel="noopener"
					xcss={navLinkStyles}
				>
					Privacy
				</Anchor>
			</li>
			<li>
				<Anchor href="/license" xcss={navLinkStyles}>
					License
				</Anchor>
			</li>
		</ul>
	</nav>
);

export default FooterContent;
