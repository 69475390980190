/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { type FC, type ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import Heading from '@atlaskit/heading';
import { Inline, Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { type IconData } from '../types';

import IconCell from './icon-cell';

const gridStyles = css({
	display: 'grid',
	gap: token('space.200', '16px'),
	gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
});

/**
 * __Grid__
 *
 * Grid component
 */
const Grid: FC<{ children?: ReactNode }> = ({ children }) => {
	return <div css={gridStyles}>{children}</div>;
};

type GridSectionProps = {
	importPath: string;
	heading: string;
	icons?: IconData[];
	testId?: string;
};

const formatTitle = (title: string) => {
	let formatted = title.replace('-', ' '); // replace the hyphen in media-services with a space
	formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);
	return formatted;
};

/**
 * __Grid section__
 *
 * Component for a grid 'section'.
 * The Icon Explorer is made up of several grids separated by headings.
 * This grid section component consists of the grid as well as the heading for the section.
 *
 */
const GridSection = ({ importPath, heading, icons, testId }: GridSectionProps) => {
	return icons ? (
		<Stack space="space.150" testId={testId}>
			<Inline space="space.150" alignBlock="baseline">
				<Heading size="medium">{formatTitle(heading)}</Heading>
				<Text color="color.text.subtle">{importPath}</Text>
			</Inline>
			<Grid>
				{icons.map((icon) => (
					<IconCell {...icon} key={icon.componentName} />
				))}
			</Grid>
		</Stack>
	) : null;
};

export default GridSection;
