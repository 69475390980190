/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useMemo, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { connectSearchBox } from 'react-instantsearch-dom';
import debounce from 'lodash/debounce';

import VisuallyHidden from '@atlaskit/visually-hidden';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { N200 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import SearchIcon from '@atlaskit/icon/glyph/search';
import type { SearchBoxProvided } from 'react-instantsearch-core';

const SEARCH_DEBOUNCE_MS = 350;

const searchWrapperStyles = xcss({
	flex: '1 1 auto',

	// @ts-expect-error hardcode input font size
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		// Safari zooms if the input has smaller text
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		font: token('font.body.large', fontFallback.body.large),
	},
});

type SearchInputProps = {
	testId?: string;
} & SearchBoxProvided;

const SearchInput = connectSearchBox<SearchInputProps>(({ refine, testId }) => {
	const [inputValue, setInputValue] = useState('');

	const handleChange = useMemo(
		() =>
			debounce((inputValue: string) => {
				refine(inputValue);
			}, SEARCH_DEBOUNCE_MS),
		[refine],
	);

	return (
		<Box xcss={searchWrapperStyles}>
			<VisuallyHidden>
				<label htmlFor="search-input">Search</label>
			</VisuallyHidden>
			<Textfield
				testId={testId ? `${testId}-search-input` : undefined}
				id="search-input"
				onChange={(e) => {
					setInputValue(e.currentTarget.value);
					handleChange(e.currentTarget.value);
				}}
				placeholder="Search"
				value={inputValue}
				elemBeforeInput={
					<Box as="span" paddingInlineStart="space.100">
						<SearchIcon label="" primaryColor={token('color.icon', N200)} />
					</Box>
				}
				autoComplete="off"
			/>
		</Box>
	);
});

export default SearchInput;
