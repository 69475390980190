/* These are the categories defined in Contentful */
export const CATEGORY_NAMES = {
	GET_STARTED: 'get started',
	BRAND: 'brand',
	TOKENS: 'tokens',
	FOUNDATIONS: 'foundations',
	COMPONENTS: 'components',
	CONTENT: 'content',
	PATTERNS: 'patterns',
	RESOURCES: 'resources',
	LICENSE: 'license',
};

export const CATEGORY_TITLES = {
	GET_STARTED: 'Get started',
	BRAND: 'Brand',
	TOKENS: 'Tokens',
	FOUNDATIONS: 'Foundations',
	COMPONENTS: 'Components',
	CONTENT: 'Content',
	PATTERNS: 'Patterns',
	RESOURCES: 'Resources',
	LICENSE: 'License',
};

export const CATEGORY_SLUGS = {
	GET_STARTED: 'get-started',
	BRAND: 'brand',
	TOKENS: 'tokens',
	COMPONENTS: 'components',
	FOUNDATIONS: 'foundations',
	CONTENT: 'content',
	PATTERNS: 'patterns',
	RESOURCES: 'resources',
	LICENSE: 'license',
};

export const TOP_NAV_ITEMS = [
	{
		title: CATEGORY_TITLES.GET_STARTED,
		name: CATEGORY_NAMES.GET_STARTED,
		slug: CATEGORY_SLUGS.GET_STARTED,
	},
	{
		title: CATEGORY_TITLES.BRAND,
		name: CATEGORY_NAMES.BRAND,
		slug: CATEGORY_SLUGS.BRAND,
	},
	{
		title: CATEGORY_TITLES.FOUNDATIONS,
		name: CATEGORY_NAMES.FOUNDATIONS,
		slug: CATEGORY_SLUGS.FOUNDATIONS,
	},
	{
		title: CATEGORY_TITLES.TOKENS,
		name: CATEGORY_NAMES.TOKENS,
		slug: CATEGORY_SLUGS.TOKENS,
	},
	{
		title: CATEGORY_TITLES.COMPONENTS,
		name: CATEGORY_NAMES.COMPONENTS,
		slug: CATEGORY_SLUGS.COMPONENTS,
	},
	{
		title: CATEGORY_TITLES.CONTENT,
		name: CATEGORY_NAMES.CONTENT,
		slug: CATEGORY_SLUGS.CONTENT,
	},
	{
		title: CATEGORY_TITLES.PATTERNS,
		name: CATEGORY_NAMES.PATTERNS,
		slug: CATEGORY_SLUGS.PATTERNS,
	},
	{
		title: CATEGORY_TITLES.RESOURCES,
		name: CATEGORY_NAMES.RESOURCES,
		slug: CATEGORY_SLUGS.RESOURCES,
	},
];

export const EMPTY_TITLE_PLACEHOLDER = 'NO TITLE';

/* Used for both description text on the page as well as description meta tag */
export const getPageDescription = (categoryName: string = '') => {
	const pageDefaultDescriptions: { [category: string]: string } = {
		'GET STARTED':
			'Start designing and developing beautiful product experiences with Atlassian Design System UI foundations, components, and standards.',
		BRAND: `Our brand reflects who we are and how we want our users to feel when they use our products. It’s the unique combination of our mission and values, and principles that drive our promise to unleash the potential of every team.`,
		FOUNDATIONS:
			'Foundations are the visual elements needed to create engaging end-to-end user experiences. This includes guidance on iconography, typography, layout, and structure.',
		COMPONENTS:
			'Components are the reusable building blocks of our design system. Each component meets a specific interaction or UI need, and has been specifically created to work together to create patterns and intuitive user experiences.',
		CONTENT:
			'Content guidance covers our voice and tone, and the mechanics of grammar and style. We use clear, concise, and conversational language to craft messages teams need to get them to where they need to go.',
		PATTERNS:
			'Patterns are reusable combinations of components that solve common user problems. These best practice solutions help users achieve their goals and help ensure consistency across experiences.',
		RESOURCES:
			'A collection of tools, kits, plugins, and guides to help simplify the creation process for our users.',
		LICENSE:
			'Atlassian Design System licensing details, including license grant, restrictions, and intellectual property information.',
		TOKENS: 'Design tokens are the single source of truth to name and store design decisions.',
	};
	const siteDefaultDescription = `Design, develop, deliver. Use Atlassian's end-to-end design language to create simple, intuitive, and beautiful experiences.`;

	const categoryName_UC = categoryName.toUpperCase();
	if (categoryName_UC in pageDefaultDescriptions) {
		return pageDefaultDescriptions[categoryName_UC];
	}
	return siteDefaultDescription;
};

export const getPageTitle = (categoryName: string = '') => {
	const pageDefaultTitles: { [category: string]: string } = {
		GET_STARTED: 'Get started',
		BRAND: 'Brand',
		FOUNDATIONS: 'Foundations',
		COMPONENTS: 'Components',
		CONTENT: 'Content',
		PATTERNS: 'Patterns',
		RESOURCES: 'Resources',
		LICENSE: 'License',
		TOKENS: 'Tokens',
	};

	const categoryName_UC = categoryName.toUpperCase().replace(' ', '_');
	if (categoryName_UC in pageDefaultTitles) {
		return pageDefaultTitles[categoryName_UC];
	}

	return '';
};

export const LOCAL_STORAGE_THEME_KEY = 'currentTheme';

/**
 * The GridItem span we use for pages like Resources, Foundations, etc.
 */
export const TYPICAL_CARD_SPAN = { xs: 6 } as const;
/** The GridItem span we use for components explicitly (it's got a lot more items) */
export const COMPONENTS_CARD_SPAN = { xs: 6, sm: 4 } as const;
