/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { token } from '@atlaskit/tokens';

const styles = css({
	margin: token('space.0', '0px'),
	font: token('font.heading.xxsmall'),
	fontFamily: token('font.family.brand.heading'),
	letterSpacing: '1px',
	textTransform: 'uppercase',
});

interface SmallCapsHeadingProps {
	as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
	testId?: string;
	color?: string;
	children: ReactNode;
}

/**
 * __Small caps heading__
 *
 * A small caps heading renders a heading with `text-transform: 'uppercase';`
 *
 */
const SmallCapsHeading = ({
	as,
	color = token('color.text'),
	testId,
	children,
}: SmallCapsHeadingProps) => {
	const Markup = as;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	const colorStyles = css({ color });

	return (
		<Markup data-testid={testId} css={[styles, colorStyles]}>
			{children}
		</Markup>
	);
};

export default SmallCapsHeading;
