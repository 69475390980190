import type { SideNavigationItem } from '../components/side-navigation';

const ensureTrailingSlash = (string: string): string => {
	if (!string.endsWith('/')) {
		return `${string}/`;
	}
	return string;
};

/**
 * Checks if `path` is a parent of, or equal to, the `relativeTo` path.
 *
 * @example
 * checkCurrentPathAncestor('/a', '/a/b') === true
 */
export const checkCurrentPathAncestor = (path: string, relativeTo: string): boolean => {
	return ensureTrailingSlash(relativeTo).startsWith(ensureTrailingSlash(path));
};

/**
 * Checks if `path` is the current page.
 *
 * @example
 * checkCurrentPath('/a', '/a') === true
 */
export const checkCurrentPath = (
	path: string,
	currentPath: string,
	subitems?: SideNavigationItem[],
): boolean => {
	const currentPathWithTrailingSlash = ensureTrailingSlash(currentPath);

	// Components have sub-pages nested under a single navigation item
	if (
		// Test if current page is a sub component page, e.g. '/components/avatar/code'
		new RegExp(/\/components\/(\w|\-)+\/(\w|\-)+/, 'g').test(currentPath) &&
		// Test if page is an ancestor of the current component page
		checkCurrentPathAncestor(path, currentPath) &&
		// Check if page is one depth above the current component page
		currentPath.split('/').length - 1 === path.split('/').length &&
		/**
		 * FIXME: This check shouldn't be necessary. It checks if the current path
		 * is a subitem of the current component.
		 * Due to component index pages redirecting to their first tabs
		 * (e.g. '/components/avatar/avatar-item' -> '/components/avatar/avatar-item/example') there
		 * is a flicker of the index page being highlighted as the "current page"
		 * in the side navigation.
		 *
		 * The code above this point can't otherwise differentiate between component subpages
		 * and tabs as their URL breadcrumbs are the same structure. One fix could be ensuring the
		 * links of index pages always default to their first tab, so the redirect never occurs
		 * to begin with (which would also prevent layout shift).
		 */

		!subitems?.some(
			({ item: { to } }) => to && ensureTrailingSlash(to) === currentPathWithTrailingSlash,
		)
	) {
		return true;
	}

	return path === currentPath || ensureTrailingSlash(path) === currentPathWithTrailingSlash;
};
