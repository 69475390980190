/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import BaseTable from './components/base-table';
import { CATEGORICAL_CHART_TOKENS } from './table-data';

/**
 * __Categorical chart tokens table__
 *
 * A categorical chart tokens table lists all the categorical chart tokens and their descriptions.
 *
 *
 */
const CategoricalChartTokensTable = () => (
	<BaseTable tableData={CATEGORICAL_CHART_TOKENS} categoryName="Token" />
);
export default CategoricalChartTokensTable;
