/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { type Hit, type BasicDoc } from 'react-instantsearch-core';
import { connectHighlight } from 'react-instantsearch-dom';
import { Box, xcss } from '@atlaskit/primitives';

import { formatAndTrimResult } from '../../utils/search';
// This type is copied from react-instantsearch-core to fix
// TS4023 (Exported variable 'Highlight' has or is using name 'HighlightPassedThru' from external module)
interface HighlightPassedThru<TDoc = any> {
	hit: Hit<TDoc>;
	attribute: string;
	highlightProperty?: string | undefined;
}

const highlightStyles = xcss({
	whiteSpace: 'normal',
});

const Highlight: React.ComponentClass<HighlightPassedThru<BasicDoc>> = connectHighlight(
	({ highlight, attribute, hit }) => {
		const parsedHit = highlight({
			highlightProperty: '_highlightResult',
			attribute,
			hit,
		});

		// Only format search results for body content,
		// skip the title and description matches
		const hits =
			parsedHit.length === 1 || attribute === 'description'
				? parsedHit
				: formatAndTrimResult(parsedHit);

		return (
			<Box as="span" xcss={highlightStyles}>
				{hits.map((part, index) =>
					part.isHighlighted ? (
						<mark
							// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							css={{
								fontWeight: 'bold',
								backgroundColor: 'inherit',
								color: 'inherit',
							}}
							key={index}
						>
							{part.value}
						</mark>
					) : (
						<Box as="span" key={index}>
							{part.value}
						</Box>
					),
				)}
			</Box>
		);
	},
);

export default Highlight;
