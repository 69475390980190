/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, type SyntheticEvent, useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import SearchIcon from '@atlaskit/icon/glyph/search';
import Stack from '@atlaskit/primitives/stack';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';

import { categories, getAllIcons } from '../helpers';
import { type AllIconsList } from '../types';

import GridSection from './grid-section';

type IconExplorerProps = {
	iconType: 'global' | 'object';
	testId?: string;
};

const searchIconStyles = css({
	paddingInlineStart: token('space.100', '8px'),
});

const SearchIconBefore = () => (
	<span css={searchIconStyles}>
		<SearchIcon label="Search" size="small" />
	</span>
);

export default function IconExplorer({ iconType, testId }: IconExplorerProps) {
	const [icons, setIcons] = useState<AllIconsList>();
	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		getAllIcons(searchQuery, iconType).then(setIcons);
	}, [setIcons, searchQuery, iconType]);

	const updateSearchQuery = useCallback(
		(query: string) => {
			setSearchQuery(query);
		},
		[setSearchQuery],
	);

	const globalIconImportPrefix = '@atlaskit/icon/glyph';
	const objectIconImportPrefix = '@atlaskit/icon-object/glyph';

	if (!icons) {
		return null;
	}

	return (
		<Stack space="space.400" testId={testId}>
			<Textfield
				width={240}
				value={searchQuery}
				placeholder="Search"
				elemBeforeInput={<SearchIconBefore />}
				onChange={(evt: SyntheticEvent<HTMLInputElement>) =>
					updateSearchQuery(evt.currentTarget.value)
				}
			/>
			{iconType === 'global' ? (
				<Fragment>
					{icons.global.length > 0 && (
						<GridSection
							importPath={globalIconImportPrefix}
							heading="Global icons"
							icons={icons?.global}
						/>
					)}

					{categories.map((category) => {
						return (
							icons[category].length > 0 && (
								<GridSection
									importPath={`${globalIconImportPrefix}/${category}`}
									heading={category}
									icons={icons?.[category]}
									key={category}
								/>
							)
						);
					})}
				</Fragment>
			) : (
				<Fragment>
					<GridSection
						importPath={`${objectIconImportPrefix}/.../16`}
						heading="Small"
						icons={icons?.['16']}
					/>

					<GridSection
						importPath={`${objectIconImportPrefix}/.../24`}
						heading="Medium"
						icons={icons?.['24']}
					/>
				</Fragment>
			)}
		</Stack>
	);
}
