/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { type ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const sidebarScrollContainerStyles = css({
	paddingBlock: token('space.200', '16px'),
});

const desktopStyles = css({
	height: '100%',
	// Only adds scroll behavior on desktop. Mobile is already handled by `@atlaskit/drawer`.
	overflowY: 'auto',
	backgroundColor: token('elevation.surface', '#FFFFFF'),
	borderInlineEnd: `1px solid ${token('color.border', N30)}`,
});

export type ScrollContainerProps = {
	device: 'desktop' | 'mobile';
	children: ReactNode;
	testId?: string;
};

/**
 * __Scroll container__
 *
 * A scroll container for the nav items in the sidebar.
 */
const ScrollContainer = ({ children, device }: ScrollContainerProps) => {
	return (
		<div css={[sidebarScrollContainerStyles, device === 'desktop' && desktopStyles]}>
			{children}
		</div>
	);
};

export default ScrollContainer;
