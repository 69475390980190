/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import upperFirst from 'lodash/upperFirst';

import { Stack } from '@atlaskit/primitives';

import SectionLink from '../../../section-link';
import type { TokenGroup } from '../grouped-tokens';

import NoResults from './no-results';
import TokenTable from './token-table';

export interface TokenGroupsProps {
	/**
	 * Offset the sticky table header to avoid overlapping existing fixed elements.
	 */
	scrollOffset?: number;
	groups: TokenGroup[];
	hasDescription?: boolean;
	hasLifecycle?: boolean;
	testId?: string;
}

const TokenGroups = ({ groups, scrollOffset = 0, testId }: TokenGroupsProps) => {
	return (
		<Stack space="space.600" testId={testId && `${testId}-token-groups`}>
			{groups.length > 0 ? (
				groups.map((group) => {
					const hasTokens = group.tokens.length > 0;

					const hasSubgroupTokens = group.subgroups?.some((subgroup) => subgroup.tokens.length > 0);

					const capitalizedGroupName = upperFirst(group.name);

					return hasTokens || hasSubgroupTokens ? (
						<Stack key={group.name} space="space.400" testId={testId && `${testId}-token-group`}>
							<Stack space="space.300">
								<SectionLink id={group.name} depth={2}>
									{capitalizedGroupName}
								</SectionLink>
								{hasTokens && (
									<TokenTable
										label={`${group.name}-${capitalizedGroupName}`}
										list={group.tokens}
										scrollOffset={scrollOffset}
										testId={testId}
									/>
								)}
							</Stack>

							{group.subgroups?.map((subgroup) =>
								subgroup.tokens.length > 0 ? (
									<Fragment key={`${group.name}-${subgroup.name}`}>
										<Stack space="space.300">
											<SectionLink id={`${group.name}-${subgroup.name}`} depth={3}>
												{upperFirst(subgroup.name)}
											</SectionLink>
											<TokenTable
												label={`${subgroup.name}-${group.name}`}
												list={subgroup.tokens}
												scrollOffset={scrollOffset}
											/>
										</Stack>
									</Fragment>
								) : null,
							)}
						</Stack>
					) : null;
				})
			) : (
				<NoResults />
			)}
		</Stack>
	);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenGroups;
