/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import Button, { IconButton } from '@atlaskit/button/new';
import Modal, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import CloseIcon from '@atlaskit/icon/glyph/editor/close';
import { hideOnMobileNavigationStyles } from '@af/design-system-docs-ui';

import SearchClient from './client';

interface SearchProps {
	isOpen: boolean;
	onClose(): void;
	testId?: string;
}

const Search = ({ isOpen, onClose, testId }: SearchProps) => {
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					onClose={onClose}
					shouldScrollInViewport
					testId={testId ? `${testId}-modal` : undefined}
				>
					<SearchClient
						onResultClick={onClose}
						testId={testId}
						closeButton={
							<IconButton
								appearance="subtle"
								onClick={onClose}
								icon={CloseIcon}
								label="Close navigation"
							/>
						}
					/>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<div css={hideOnMobileNavigationStyles}>
						<ModalFooter>
							<Button appearance="subtle" onClick={onClose}>
								Close
							</Button>
						</ModalFooter>
					</div>
				</Modal>
			)}
		</ModalTransition>
	);
};

export default Search;
