import objectMetadata from '@atlaskit/icon-object/metadata';
import globalMetadata from '@atlaskit/icon/metadata';

import { type AllIconsList, type IconData, type IconPage, type IconsList } from './types';

export const categories = ['editor', 'emoji', 'bitbucket', 'jira', 'media-services'];

export const sortIntoCategories = (icons: IconData[], iconType: IconPage) => {
	let categorisedIcons = {} as AllIconsList;
	if (iconType === 'global') {
		categorisedIcons = {
			global: [],
			editor: [],
			emoji: [],
			bitbucket: [],
			jira: [],
			'media-services': [],
		} as AllIconsList;
		icons.forEach((icon) => {
			// example packageName = @atlaskit/icon/glyph/bitbucket/compare
			// index 3 will be 'bitbucket'
			const category = icon.packageName.split('/')[3];
			if (categories.includes(category)) {
				categorisedIcons[category].push(icon);
			} else {
				// packageName could also be a general/global icon = '@atlaskit/icon/glyph/activity'
				categorisedIcons.global.push(icon);
			}
		});
	} else if (iconType === 'object') {
		// e.g. @atlaskit/icon-object/glyph/branch/24
		categorisedIcons = {
			'16': [],
			'24': [],
		} as AllIconsList;
		const sizes = ['16', '24'];
		const numbersRegex = /\d+/g;
		icons.forEach((icon) => {
			const size = icon.componentName.match(numbersRegex)?.[0]; // componentName example = Branch24Icon
			if (size && sizes.includes(size)) {
				categorisedIcons[size].push(icon);
			}
		});
	}
	return categorisedIcons;
};

// example: media-services/vid-play
// should become Vid play
export const getIconName = (name: string) => {
	// remove prefix (e.g. media-services/)
	const nameAsArray = name.split('/');
	if (nameAsArray.length > 1) {
		if (nameAsArray[1] === '16' || nameAsArray[1] === '24') {
			// for object icons: e.g. blog/24, where the number denotes the size
			nameAsArray.pop();
		} else {
			nameAsArray.shift();
		}
	}
	let iconName = nameAsArray.join('');

	// replace any hyphens with spaces
	// and capitalise only the first letter
	if (iconName) {
		iconName = iconName.replace('-', ' ');
		iconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
	}

	return iconName;
};

// use this to cache the result - this should probs be encapsuated in a signleton class
let globalIconsPromise: Promise<unknown> | null = null;

// get global icon info as a Promise which returns a Record
export const getGlobalIcons = () => {
	if (!globalIconsPromise) {
		globalIconsPromise = Promise.all(
			Object.keys(globalMetadata).map(async (name: string) => {
				const icon = await import(
					/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon/glyph/${name}`
				);
				return { name, icon: icon.default };
			}),
		)
			.then((newData) =>
				newData
					.map((icon) => {
						const metadata = globalMetadata[icon.name];
						return {
							[icon.name]: {
								keywords: metadata.keywords,
								component: icon.icon,
								componentName: metadata.componentName,
								packageName: metadata.package,
								iconName: getIconName(icon.name),
							},
						};
					})
					.reduce((acc, b) => ({ ...acc, ...b })),
			)
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error(error);
			});
	}

	return globalIconsPromise;
};

// we use this to cache the result
let objectIconsPromise: Promise<unknown> | null = null;

export const getObjectIcons = () => {
	if (!objectIconsPromise) {
		objectIconsPromise = Promise.all(
			Object.keys(objectMetadata).map(async (name: string) => {
				const icon = await import(
					/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon-object/glyph/${name}`
				);
				return { name, icon: icon.default };
			}),
		)
			.then((newData) =>
				newData
					.map((icon) => {
						const metadata = objectMetadata[icon.name];
						return {
							[icon.name]: {
								keywords: metadata.keywords,
								component: icon.icon,
								componentName: metadata.componentName,
								packageName: metadata.package,
								iconName: getIconName(icon.name),
							},
						};
					})
					.reduce((acc, b) => ({ ...acc, ...b })),
			)
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error(error);
			});
	}
	return objectIconsPromise;
};

export const getAllIcons = async (query: string, iconType: IconPage): Promise<AllIconsList> => {
	const iconsList = iconType === 'global' ? await getGlobalIcons() : await getObjectIcons();

	const icons = filterIcons(iconsList as IconsList, query);

	return sortIntoCategories(icons, iconType);
};

export const filterIcons = (icons: IconsList, query: string) => {
	const lowerCaseQuery = query.toLowerCase();
	return Object.keys(icons)
		.map((index) => icons[index])
		.filter((icon) =>
			icon.keywords
				.map((keyword) => (keyword.includes(lowerCaseQuery) ? 1 : 0))
				.reduce((allMatches: number, match: number) => allMatches + match, 0),
		);
};
