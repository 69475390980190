/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { media } from '@atlaskit/primitives';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { SmallCapsHeading } from '@af/design-system-docs-ui';

const headingItemStyles = css({
	paddingBlockEnd: token('space.150', '12px'),
	paddingInlineStart: token('space.400', '32px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		paddingBlockStart: token('space.400', '32px'),
	},
	[media.below.sm]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:first-of-type': {
			paddingBlockStart: token('space.100', '8px'),
			paddingBlockEnd: token('space.050', '4px'),
		},
	},
});

type HeadingItemProps = { children: React.ReactNode; testId?: string };

/**
 * __Heading item__
 *
 * Displays a heading inside the side navigation.
 */
const HeadingItem = ({ children, testId }: HeadingItemProps) => (
	<div css={headingItemStyles} data-testid={testId}>
		<SmallCapsHeading as="h2" color={token('color.text', N800)}>
			{children}
		</SmallCapsHeading>
	</div>
);

export default HeadingItem;
