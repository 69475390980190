/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { token } from '@atlaskit/tokens';

import questions from '../data/questions';
import { type Question, type questionID } from '../data/types';

const questionContainerStyles = css({
	padding: token('space.200', '16px'),
	background: token('color.background.selected.bold'),
	borderRadius: token('border.radius.200', '8px'),
	marginBlockEnd: token('space.150', '12px'),
});

const questionStyles = css({
	color: token('color.text.inverse'),
	fontSize: '20px',
	fontWeight: 500,
	lineHeight: '24px',
});

const questionDescriptionStyles = css({
	color: token('color.text.inverse'),
	fontSize: '14px',
	lineHeight: '20px',
});

/**
 * __Current question__
 *
 * A current question on the bottom of question list on the right-hand-side token wizard modal dialog.
 *
 */
const CurrentQuestion = ({ questionId }: { questionId: questionID }) => {
	const question: Question = questions[questionId];
	return (
		<div css={questionContainerStyles}>
			<h5 css={questionStyles}>{question.title}</h5>
			{question.metadata?.description && (
				<p css={questionDescriptionStyles}>{question.metadata?.description}</p>
			)}
		</div>
	);
};

export default CurrentQuestion;
