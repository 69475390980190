import React from 'react';

import { SpotlightCard } from '../../src';

const SpotlightCardDefaultExample = () => {
	return (
		<SpotlightCard>
			Select the project name and icon to quickly switch between your most recent projects.
		</SpotlightCard>
	);
};

export default SpotlightCardDefaultExample;
