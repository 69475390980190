import { CATEGORY_SLUGS } from '../../utils/constants';
import titleify from '../../utils/titleify';
import type { SideNavigationItem } from '../../components/side-navigation';

import type { ComponentPageInfo, PackageInfo } from '.';
import { type Categories } from './index';
const { constellationCategories } = require('../../../constellation.config');

/**
 * __Get subitems__
 *
 * Constructs a navigation hierarchy of subitems for
 * a single package.
 */
const getSubitems = ({
	componentPages,
	packageInfo,
	parentPath,
}: {
	componentPages: ComponentPageInfo[];
	packageInfo: PackageInfo;
	parentPath: string;
}): SideNavigationItem[] => {
	const rootPages = componentPages.filter((page) => {
		const subPath = page.path.substring(parentPath.length);
		const hasOnlyLeadingSlash = subPath.lastIndexOf('/') === 0;
		return hasOnlyLeadingSlash;
	});

	const hierarchyLevel: SideNavigationItem[] = rootPages.map((rootPage) => {
		const subPages = componentPages.filter(({ path }) => path.startsWith(rootPage.path));
		const subPageHierarchy = getSubitems({
			componentPages: subPages,
			packageInfo,
			parentPath: rootPage.path,
		});

		return {
			item: {
				title:
					rootPage.pageContext.title ||
					titleify(rootPage.path.match(/\/(?<name>[^/]+)$/)?.groups?.name ?? ''),
				slug: rootPage.pageContext.slug,
				status: rootPage.pageContext.status,
				to: rootPage.path,
			},
			subitems: subPageHierarchy,
		};
	});

	return hierarchyLevel;
};

/**
 * __Get package page__
 *
 * Constructs a root-level package page navigation hierarchy
 * with subitems for a single package.
 */
export const getPackagePage = ({
	componentPages,
	packageInfo,
}: {
	componentPages: ComponentPageInfo[];
	packageInfo: PackageInfo;
}): SideNavigationItem => {
	const sortedPages = componentPages.sort((pageA, pageB) => {
		if (pageA.pageContext.sortKey < pageB.pageContext.sortKey) {
			return -1;
		}
		if (pageA.pageContext.sortKey > pageB.pageContext.sortKey) {
			return 1;
		}
		return 0;
	});

	const rootPathRegex = new RegExp(`^\/${CATEGORY_SLUGS.COMPONENTS}\/${packageInfo.slug}([^-]|$)`);
	const packagePages = sortedPages.filter((page) => rootPathRegex.test(page.path));
	const path = `/${CATEGORY_SLUGS.COMPONENTS}/${packageInfo.slug}`;

	return {
		item: {
			title: packageInfo.title,
			slug: packageInfo.slug,
			status: packageInfo.status || undefined,
			to: path,
		},
		subitems: getSubitems({
			componentPages: packagePages,
			packageInfo,
			parentPath: path,
		}),
	};
};

/**
 * __Get package pages__
 *
 * Constructs a navigation hierarchy of package pages
 * based on categories and component pages supplied from Gatsby.
 */
const getPackagePages = ({
	categories,
	componentPages,
}: {
	categories: Categories;
	componentPages: ComponentPageInfo[];
}): SideNavigationItem[] => {
	return (
		categories
			.concat()
			// Sort categories according to configuration order defined in `constellation.config`.
			.sort((categoryA, categoryB) => {
				const aIndex = constellationCategories.packageCategories.indexOf(categoryA.name);
				const bIndex = constellationCategories.packageCategories.indexOf(categoryB.name);

				if (aIndex < bIndex) {
					return -1;
				}
				if (aIndex > bIndex) {
					return 1;
				}
				return 0;
			})
			.map(({ name, packages }) => {
				const packageWithFlatStructure = getTopLevelChildItems(packages);
				packages = packageWithFlatStructure?.length >= 1 ? packageWithFlatStructure : packages;

				return {
					item: {
						title: name,
						type: 'heading',
					},
					subitems: packages
						// Filter sub-packages
						.filter(
							// '/' is to exclude drag-and-drop packages which are intended to be nested
							// primitives slugs include '/' but they should be flat in structure (see: DSP-9051)
							({ slug }) => !slug.includes('/') || packageWithFlatStructure.length,
						)
						.map((packageInfo) => {
							return getPackagePage({
								componentPages,
								packageInfo,
							});
						}),
				};
			})
	);
};

// reads packages with a `pages` entry and returns them
const getTopLevelChildItems = (packages: PackageInfo[]): PackageInfo[] => {
	return packages
		.filter((pkg) => pkg.pages && pkg.pages.length)
		.map(({ pages }) => pages)
		.flat()
		.filter((page): page is PackageInfo => page !== undefined);
};

export default getPackagePages;
