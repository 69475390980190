/**
 * NOTE:
 *
 * This file is automatically generated by the build process.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 *
 * To change the format of this file, modify build/icon/createIconDocs.js.
 * Add synonyms in icon/icons/synonyms.js.
 */

interface Data {
	keywords: string[];
	componentName: string;
	package: string;
}

const metaData: Record<string, Data> = {
	'blog/16': {
		keywords: ['blog/16', 'blog16', 'object', 'icon-object'],
		componentName: 'Blog16Icon',
		package: '@atlaskit/icon-object/glyph/blog/16',
	},
	'branch/16': {
		keywords: ['branch/16', 'branch16', 'object', 'icon-object'],
		componentName: 'Branch16Icon',
		package: '@atlaskit/icon-object/glyph/branch/16',
	},
	'bug/16': {
		keywords: ['bug/16', 'bug16', 'object', 'icon-object'],
		componentName: 'Bug16Icon',
		package: '@atlaskit/icon-object/glyph/bug/16',
	},
	'calendar/16': {
		keywords: ['calendar/16', 'calendar16', 'object', 'icon-object'],
		componentName: 'Calendar16Icon',
		package: '@atlaskit/icon-object/glyph/calendar/16',
	},
	'changes/16': {
		keywords: ['changes/16', 'changes16', 'object', 'icon-object'],
		componentName: 'Changes16Icon',
		package: '@atlaskit/icon-object/glyph/changes/16',
	},
	'code/16': {
		keywords: ['code/16', 'code16', 'object', 'icon-object'],
		componentName: 'Code16Icon',
		package: '@atlaskit/icon-object/glyph/code/16',
	},
	'commit/16': {
		keywords: ['commit/16', 'commit16', 'object', 'icon-object'],
		componentName: 'Commit16Icon',
		package: '@atlaskit/icon-object/glyph/commit/16',
	},
	'epic/16': {
		keywords: ['epic/16', 'epic16', 'object', 'icon-object'],
		componentName: 'Epic16Icon',
		package: '@atlaskit/icon-object/glyph/epic/16',
	},
	'improvement/16': {
		keywords: ['improvement/16', 'improvement16', 'object', 'icon-object'],
		componentName: 'Improvement16Icon',
		package: '@atlaskit/icon-object/glyph/improvement/16',
	},
	'incident/16': {
		keywords: ['incident/16', 'incident16', 'object', 'icon-object'],
		componentName: 'Incident16Icon',
		package: '@atlaskit/icon-object/glyph/incident/16',
	},
	'issue/16': {
		keywords: ['issue/16', 'issue16', 'object', 'icon-object'],
		componentName: 'Issue16Icon',
		package: '@atlaskit/icon-object/glyph/issue/16',
	},
	'new-feature/16': {
		keywords: ['new-feature/16', 'newfeature16', 'object', 'icon-object'],
		componentName: 'NewFeature16Icon',
		package: '@atlaskit/icon-object/glyph/new-feature/16',
	},
	'page/16': {
		keywords: ['page/16', 'page16', 'object', 'icon-object'],
		componentName: 'Page16Icon',
		package: '@atlaskit/icon-object/glyph/page/16',
	},
	'problem/16': {
		keywords: ['problem/16', 'problem16', 'object', 'icon-object'],
		componentName: 'Problem16Icon',
		package: '@atlaskit/icon-object/glyph/problem/16',
	},
	'pull-request/16': {
		keywords: ['pull-request/16', 'pullrequest16', 'object', 'icon-object'],
		componentName: 'PullRequest16Icon',
		package: '@atlaskit/icon-object/glyph/pull-request/16',
	},
	'question/16': {
		keywords: ['question/16', 'question16', 'object', 'icon-object'],
		componentName: 'Question16Icon',
		package: '@atlaskit/icon-object/glyph/question/16',
	},
	'story/16': {
		keywords: ['story/16', 'story16', 'object', 'icon-object'],
		componentName: 'Story16Icon',
		package: '@atlaskit/icon-object/glyph/story/16',
	},
	'subtask/16': {
		keywords: ['subtask/16', 'subtask16', 'object', 'icon-object'],
		componentName: 'Subtask16Icon',
		package: '@atlaskit/icon-object/glyph/subtask/16',
	},
	'task/16': {
		keywords: ['task/16', 'task16', 'object', 'icon-object'],
		componentName: 'Task16Icon',
		package: '@atlaskit/icon-object/glyph/task/16',
	},
	'blog/24': {
		keywords: ['blog/24', 'blog24', 'object', 'icon-object'],
		componentName: 'Blog24Icon',
		package: '@atlaskit/icon-object/glyph/blog/24',
	},
	'branch/24': {
		keywords: ['branch/24', 'branch24', 'object', 'icon-object'],
		componentName: 'Branch24Icon',
		package: '@atlaskit/icon-object/glyph/branch/24',
	},
	'bug/24': {
		keywords: ['bug/24', 'bug24', 'object', 'icon-object'],
		componentName: 'Bug24Icon',
		package: '@atlaskit/icon-object/glyph/bug/24',
	},
	'calendar/24': {
		keywords: ['calendar/24', 'calendar24', 'object', 'icon-object'],
		componentName: 'Calendar24Icon',
		package: '@atlaskit/icon-object/glyph/calendar/24',
	},
	'changes/24': {
		keywords: ['changes/24', 'changes24', 'object', 'icon-object'],
		componentName: 'Changes24Icon',
		package: '@atlaskit/icon-object/glyph/changes/24',
	},
	'code/24': {
		keywords: ['code/24', 'code24', 'object', 'icon-object'],
		componentName: 'Code24Icon',
		package: '@atlaskit/icon-object/glyph/code/24',
	},
	'commit/24': {
		keywords: ['commit/24', 'commit24', 'object', 'icon-object'],
		componentName: 'Commit24Icon',
		package: '@atlaskit/icon-object/glyph/commit/24',
	},
	'epic/24': {
		keywords: ['epic/24', 'epic24', 'object', 'icon-object'],
		componentName: 'Epic24Icon',
		package: '@atlaskit/icon-object/glyph/epic/24',
	},
	'improvement/24': {
		keywords: ['improvement/24', 'improvement24', 'object', 'icon-object'],
		componentName: 'Improvement24Icon',
		package: '@atlaskit/icon-object/glyph/improvement/24',
	},
	'incident/24': {
		keywords: ['incident/24', 'incident24', 'object', 'icon-object'],
		componentName: 'Incident24Icon',
		package: '@atlaskit/icon-object/glyph/incident/24',
	},
	'issue/24': {
		keywords: ['issue/24', 'issue24', 'object', 'icon-object'],
		componentName: 'Issue24Icon',
		package: '@atlaskit/icon-object/glyph/issue/24',
	},
	'new-feature/24': {
		keywords: ['new-feature/24', 'newfeature24', 'object', 'icon-object'],
		componentName: 'NewFeature24Icon',
		package: '@atlaskit/icon-object/glyph/new-feature/24',
	},
	'page/24': {
		keywords: ['page/24', 'page24', 'object', 'icon-object'],
		componentName: 'Page24Icon',
		package: '@atlaskit/icon-object/glyph/page/24',
	},
	'problem/24': {
		keywords: ['problem/24', 'problem24', 'object', 'icon-object'],
		componentName: 'Problem24Icon',
		package: '@atlaskit/icon-object/glyph/problem/24',
	},
	'pull-request/24': {
		keywords: ['pull-request/24', 'pullrequest24', 'object', 'icon-object'],
		componentName: 'PullRequest24Icon',
		package: '@atlaskit/icon-object/glyph/pull-request/24',
	},
	'question/24': {
		keywords: ['question/24', 'question24', 'object', 'icon-object'],
		componentName: 'Question24Icon',
		package: '@atlaskit/icon-object/glyph/question/24',
	},
	'story/24': {
		keywords: ['story/24', 'story24', 'object', 'icon-object'],
		componentName: 'Story24Icon',
		package: '@atlaskit/icon-object/glyph/story/24',
	},
	'subtask/24': {
		keywords: ['subtask/24', 'subtask24', 'object', 'icon-object'],
		componentName: 'Subtask24Icon',
		package: '@atlaskit/icon-object/glyph/subtask/24',
	},
	'task/24': {
		keywords: ['task/24', 'task24', 'object', 'icon-object'],
		componentName: 'Task24Icon',
		package: '@atlaskit/icon-object/glyph/task/24',
	},
};

export default metaData;
