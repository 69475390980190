import React from 'react';

import { DateTimePicker } from '../../src';

const DateTimePickerLocaleExample = () => (
	<>
		<DateTimePicker locale={'en-US'} />
		<br />
		<DateTimePicker locale={'ja-JP'} />
	</>
);

export default DateTimePickerLocaleExample;
