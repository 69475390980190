/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import type { BasicDoc, Hit } from 'react-instantsearch-core';
import { Inline, Stack, Box, xcss } from '@atlaskit/primitives';
import { N500 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';

import { CATEGORY_TITLES } from '../../utils/constants';

import { LinkItem } from './items';
import Highlight from './highlight';

const iconColorMapping = {
	[CATEGORY_TITLES.COMPONENTS]: token('color.icon.accent.green', '#22A06B'),
	[CATEGORY_TITLES.TOKENS]: token('color.icon.accent.yellow', '#B38600'),
	[CATEGORY_TITLES.FOUNDATIONS]: token('color.icon.accent.teal', '#1D9AAA'),
	[CATEGORY_TITLES.RESOURCES]: token('color.icon.accent.magenta', '#CD519D'),
	[CATEGORY_TITLES.PATTERNS]: token('color.icon.accent.orange', '#D97008'),
	[CATEGORY_TITLES.CONTENT]: token('color.icon.accent.red', '#E34935'),
	[CATEGORY_TITLES.BRAND]: token('color.icon.accent.blue', '#1D7AFC'),
	[CATEGORY_TITLES.GET_STARTED]: token('color.icon.accent.purple', '#8270DB'),
};

type ResultItemProps = {
	onClick?: () => void;
	hit: Hit<BasicDoc>;
	indexName: string;
};

const dotStyles = xcss({
	height: '8px',
	width: '8px',
	marginBlockStart: 'space.050',
	borderRadius: 'border.radius.circle',
});

const textStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	color: 'color.text.subtle',
	margin: 'space.0',
});

const ResultItem = ({ indexName, hit, onClick }: ResultItemProps) => {
	let path = hit.path;
	const hasMatchInTitle =
		hit._highlightResult.title?.matchLevel && hit._highlightResult.title?.matchLevel !== 'none';
	let hasMatchInDescription =
		hit._highlightResult.description?.matchLevel &&
		hit._highlightResult.description?.matchLevel !== 'none';
	const hasMatchInContent =
		hit._highlightResult.content?.matchLevel && hit._highlightResult.content?.matchLevel !== 'none';

	if (
		indexName === CATEGORY_TITLES.COMPONENTS &&
		hasMatchInContent &&
		!hasMatchInTitle &&
		!hasMatchInDescription
	) {
		path = `${hit.path}/${hit.contentTab}`;
	}

	let showDescription = hasMatchInDescription;
	if (!hasMatchInDescription && !hasMatchInContent) {
		showDescription = true;
	}

	return (
		<LinkItem to={path} onClick={onClick}>
			<Inline space="space.100" alignBlock="center" spread="space-between">
				<Inline space="space.100">
					<Box xcss={dotStyles} style={{ backgroundColor: iconColorMapping[indexName] }} />
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<Stack space="space.100" xcss={xcss({ width: '100%' })}>
						<Box
							as="h5"
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							xcss={xcss({
								color: 'color.text',
								whiteSpace: 'normal',
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
								font: token('font.heading.small', fontFallback.heading.small),
							})}
						>
							<Highlight hit={hit} attribute="title" />
						</Box>
						{showDescription && (
							<Box as="p" xcss={textStyles}>
								<Highlight hit={hit} attribute="description" />
							</Box>
						)}
						{hasMatchInContent && (
							<Box as="p" xcss={textStyles}>
								<Highlight hit={hit} attribute="content" />
							</Box>
						)}
					</Stack>
				</Inline>
				<ChevronRightIcon primaryColor={token('color.icon.subtle', N500)} label="arrow_right" />
			</Inline>
		</LinkItem>
	);
};

export default ResultItem;
