import { FlagsProvider } from "../../../../../../packages/design-system/flag/src/index";
import FlagsProviderExample from "../../../../../../packages/design-system/flag/examples/constellation/flags-provider";
import FlagsProviderShowFlag from "../../../../../../packages/design-system/flag/examples/constellation/flags-provider-show-flag";
import RAW_UNSAFEUPUPSLASHUPUPSLASHsrcSLASHindex from "!!raw-loader!../../../../../../packages/design-system/flag/src/index";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flags-provider";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider_show_flag from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flags-provider-show-flag";
import * as React from 'react';
export default {
  FlagsProvider,
  FlagsProviderExample,
  FlagsProviderShowFlag,
  RAW_UNSAFEUPUPSLASHUPUPSLASHsrcSLASHindex,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider_show_flag,
  React
};