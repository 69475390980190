import React, { useCallback, useState } from 'react';

import CopyIcon from '@atlaskit/icon/glyph/copy';
import { Box, Pressable, xcss } from '@atlaskit/primitives';

import CopyToClipboard from './copy-to-clipboard';

const copyValueButtonStyles = xcss({
	display: 'inline-flex',
	paddingBlock: 'space.025',
	paddingInline: 'space.0',
	alignItems: 'center',
	appearance: 'none',
	background: 'none',
	border: 'none',
	borderRadius: 'border.radius',
	// @ts-ignore
	color: 'inherit',
	cursor: 'pointer',

	':hover': {
		textDecoration: 'underline',
	},
});

const copyValueIconStyles = xcss({
	opacity: 0,
});

interface CopyButtonProps {
	value: string;
	children: React.ReactNode;
	className?: string;
}

/**
 * __Copy button__
 *
 * A clickable icon that copies a string to the user's clipboard.
 *
 */
const CopyButton = ({ value, children }: CopyButtonProps) => {
	const [isCopyIconVisible, setIsCopyIconVisible] = useState(false);

	const showCopyIcon = useCallback(() => {
		setIsCopyIconVisible(true);
	}, []);
	const hideCopyIcon = useCallback(() => {
		setIsCopyIconVisible(false);
	}, []);

	return (
		<CopyToClipboard
			value={value}
			messages={{
				prompt: `Copy '${value}' to clipboard`,
				success: 'Copied!',
				fail: 'Copy failed',
			}}
		>
			{({ copy }) => (
				<Pressable
					xcss={copyValueButtonStyles}
					onClick={copy}
					onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
						event.preventDefault();
					}}
					onMouseEnter={showCopyIcon}
					onMouseLeave={hideCopyIcon}
					onFocus={showCopyIcon}
					onBlur={hideCopyIcon}
				>
					{children}
					<Box xcss={!isCopyIconVisible && copyValueIconStyles}>
						<CopyIcon size="small" label="" />
					</Box>
				</Pressable>
			)}
		</CopyToClipboard>
	);
};

export default CopyButton;
