/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import BaseTable from './components/base-table';
import { SEMANTIC_CHART_TOKENS } from './table-data';

/**
 * __Semantic chart tokens table__
 *
 * A semantic chart tokens table lists all the semantic chart tokens and their descriptions.
 *
 */
const SemanticChartTokensTable = () => (
	<BaseTable categoryName="Token" tableData={SEMANTIC_CHART_TOKENS} />
);
export default SemanticChartTokensTable;
