import React from 'react';

import { Box, Pressable, xcss } from '@atlaskit/primitives';

import { type Answer, type Path } from '../data/types';

const answerSummaryStyles = xcss({
	fontSize: '20px',
	fontWeight: 500,
	lineHeight: '24px',
	color: 'color.text',
});

const answerSummarySelectedStyles = xcss({
	color: 'color.text.selected',
});

const answerDescriptionStyles = xcss({
	fontSize: '14px',
	lineHeight: '20px',
	marginBlockStart: 'space.050',
});

const baseAnswerStyles = xcss({
	width: '100%',
	paddingBlock: 'space.100',
	paddingInline: 'space.150',
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.200',
	color: 'color.text',
	marginBlockEnd: 'space.100',
	textAlign: 'start',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const selectedStyles = xcss({
	backgroundColor: 'color.background.selected',
	borderColor: 'color.border.selected',
	color: 'color.text.selected',
	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
	':active': {
		backgroundColor: 'color.background.selected.pressed',
	},
});

/**
 * __Answer card__
 *
 * An answer card on the left hand side of the token wizard modal
 *
 */
const AnswerCard = ({
	answer,
	onClick,
	path,
}: {
	answer: Answer;
	onClick: (...args: any) => void;
	path: [Path] | [];
}) => {
	const selected = !!path.find((qs) => qs.answer === answer.summary);
	return (
		<Pressable
			xcss={[baseAnswerStyles, selected && selectedStyles]}
			key={answer.summary}
			onClick={onClick}
		>
			<Box as="h5" xcss={[answerSummaryStyles, selected && answerSummarySelectedStyles]}>
				{answer.summary}
			</Box>
			{answer.description && (
				<Box as="p" xcss={answerDescriptionStyles}>
					{answer.description}
				</Box>
			)}
		</Pressable>
	);
};

export default AnswerCard;
