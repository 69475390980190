/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import Button from '@atlaskit/button/new';
import SearchIcon from '@atlaskit/icon/glyph/search';

interface SearchButtonProps {
	onClick?(): void;
}

const SearchButton = ({ onClick }: SearchButtonProps) => {
	return (
		<Button onClick={onClick} iconBefore={SearchIcon} appearance="subtle">
			Search
		</Button>
	);
};

export default SearchButton;
