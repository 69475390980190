/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type PropsWithChildren, type ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { Box, Anchor, xcss } from '@atlaskit/primitives';
import { CustomItem, type CustomItemProps } from '@atlaskit/menu';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { HeadingIcon } from '@af/design-system-docs-ui';
import { CATEGORY_TITLES } from '../../utils/constants';

type HeadingItemProps = PropsWithChildren<{
	indexName: string;
}>;

const backgroundMapping = {
	[CATEGORY_TITLES.COMPONENTS]: token('color.background.accent.green.subtlest', '#DFFCF0'),
	[CATEGORY_TITLES.TOKENS]: token('color.background.accent.yellow.subtlest', '#FFF7D6'),
	[CATEGORY_TITLES.FOUNDATIONS]: token('color.background.accent.teal.subtlest', '#E3FAFC'),
	[CATEGORY_TITLES.RESOURCES]: token('color.background.accent.magenta.subtlest', '#FFECF8'),
	[CATEGORY_TITLES.PATTERNS]: token('color.background.accent.orange.subtlest', '#FFF4E5'),
	[CATEGORY_TITLES.CONTENT]: token('color.background.accent.red.subtlest', '#FFEDEB'),
	[CATEGORY_TITLES.BRAND]: token('color.background.accent.blue.subtlest', '#E9F2FF'),
	[CATEGORY_TITLES.GET_STARTED]: token('color.background.accent.purple.subtlest', '#F3F0FF'),
};
const headingItemStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	paddingBlock: 'space.150',
	paddingInline: 'space.150',
	marginBlock: 'space.0',
	marginInline: 'space.200',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	borderRadius: 'border.radius.200',
	gap: 'space.100',
});

export const HeadingItem = ({ children, indexName }: HeadingItemProps) => (
	<CustomItem
		component={({ children }) => (
			<Box
				xcss={headingItemStyles}
				style={{
					backgroundColor: backgroundMapping[indexName],
				}}
			>
				<HeadingIcon heading={indexName} />
				{children}
			</Box>
		)}
	>
		{children}
	</CustomItem>
);

interface LinkItemProps extends CustomItemProps {
	to: string;
	children: ReactNode;
}

const linkItemStyles = xcss({
	display: 'block',
	paddingBlock: 'space.200',
	paddingInline: 'space.300',
	textDecoration: 'none',
	':hover': {
		textDecoration: 'none',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const linkItemContentStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		lineHeight: '20px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	em: {
		fontWeight: token('font.weight.bold', '700'),
		fontStyle: 'inherit',
	},
});

export const LinkItem = ({ to, children, ...props }: LinkItemProps) => (
	<CustomItem
		{...props}
		component={({ children, ...rest }) => (
			<Anchor href={to} xcss={linkItemStyles} {...rest}>
				<div css={linkItemContentStyles}>{children}</div>
			</Anchor>
		)}
	>
		{children}
	</CustomItem>
);
