/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { N10 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import FooterContent from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/content/footer-content';

const footerStyles = css({
	backgroundColor: token('color.background.neutral', N10),
	gridArea: 'footer',
});

type FooterProps = {
	id?: string;
};

/**
 * __Footer__
 *
 * The site footer.
 */
const Footer = ({ id }: FooterProps) => {
	return (
		<footer id={id} css={footerStyles}>
			<FooterContent />
		</footer>
	);
};

export default Footer;
